<template>
  <div class="login">
    <!-- <img class="smallimg" alt="Vue logo" src="../assets/user.png" /> -->
    <h1>Logout</h1>
  </div>
</template>
<script>
// import store from "@/store/index.js"; // top level store object

export default {
  data: () => ({}),
  mounted() {
    this.$store.commit("logout");
    this.$router.push("/");
  },
};
</script>
